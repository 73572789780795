import * as React from "react";
import { Link } from "gatsby";

// import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function Imitosis({ data }) {
  return (
    <Layout>
      <Helmet title="Imitosis | Andrew Bird" />

      <h1>Imitosis</h1>

      <h2>Andrew Bird</h2>

      <h4>Key: Cm</h4>

      <h4>Tempo: 108</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Verse>
          <p>He's keeping busy, yeah, he's bleeding stones</p>
          <p>With his machinations and his palindromes</p>
          <p>It was anything but hear the voice</p>
          <p>Anything but hear the voice</p>
          <p>It was anything but hear the voice that says that we're all basically alone</p>
        </Verse>
        <Verse>
          <p>Poor Professor Pynchon had only good intentions when he</p>
          <p>Put his Bunsen burners all away</p>
          <p>And turning to a playground in a Petri dish</p>
          <p>Where single cells would swing their fists at anything that looks like easy prey</p>
        </Verse>
        <Chorus>
          <p>In this nature show that rages every day</p>
          <p>It was then he heard his intuition say</p>
          <p>We were all basically alone</p>
        </Chorus>
        <Verse>
          <p>And despite what all his studies had shown</p>
          <p>That what's mistaken for closeness is just a case of mitosis</p>
          <p>And why do some show no mercy while others are painfully shy?</p>
          <p>Tell me doctor, can you quantify?</p>
          <p>He just wants to know the reason why</p>
          <p>The reason why</p>
        </Verse>
        <Verse>
          <p>Why do they congregate in groups of four,</p>
          <p>Scatter like a billion spores and let the wind just carry them away?</p>
          <p>How can kids be so mean?</p>
          <p>Our famous doctor tried to glean as he went home at the end of the day</p>
        </Verse>
        <Chorus>
          <p>In this nature show that rages every day</p>
          <p>It was then he heard his intuition say</p>
          <p>We were all basically alone</p>
        </Chorus>
        <Verse>
          <p>Despite what all his studies had shown</p>
          <p>That what's mistaken for closeness is just a case of mitosis</p>
          <p>Sure fatal doses of malcontent through osmosis</p>
          <p>And why do some show no mercy while others are painfully shy?</p>
          <p>Tell me doctor, can you quantify</p>
          <p>The reason why?</p>
        </Verse>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "imitosis.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;